<template>
  <div class="pageContent customerPaid">
    <div class="container mt3 mb3">
    <div id="barcode"></div>
      <h1>
        <span v-if="deductFromRefund== true">{{ getText('cp001') }}</span>
        <span v-else>{{ getText('cp002') }}</span>
      </h1>
      <div>
        <p v-if="deductFromRefund== true">{{ getText('cp003') }}</p>      
        <p>{{ getText('cp004') }}</p>
        <p>{{ getText('cp005') }}</p>
        <div class="columns">
          <div class="column is-7">
            <div class="borderBottom1">
              <b-radio v-model="settlementChoice" class="is-primary has-text-weight-bold" native-value="pay" :disabled="locked == true">{{ getText('cp009') }}</b-radio>
              <p class="is-size-6">
                {{ getText('cp010') }}
                <br>
                {{ getText('cp011') }}
              </p>
            </div>
            <!-- Options for alternative choices (like partial refunds) -->
            <!-- <div v-if="deductFromRefund== true" class="borderBottom1">
              <b-radio v-model="settlementChoice" class="is-primary has-text-weight-bold" native-value="deduct" :disabled="locked == true">{{ getText('cp006') }}</b-radio>
              <p class="is-size-6">
                {{ getText('cp007') }}
                <br>
                {{ getText('cp008') }}
              </p>
            </div> -->
          </div>
          <div class="column">
            <ReturnItemSummary :items="data.items" />
            <div class="p1">
              <b-checkbox v-model="sendEmail" v-bind:false-value="0" v-bind:true-value="1">{{ getText('sendToEmail', true) }}</b-checkbox>
              <b-input 
                type="text" 
                v-model="customerEmail" 
                :disabled="sendEmail != true" 
                :placeholder="getText('yourEmail', true)"></b-input>

            </div>
            <p class="has-text-centered" v-if="emailStatus == 'sending'"><b-icon class="mr025" icon="timer-sand" ></b-icon>{{ getText('emailSending', true) }}</p>
            <p class="has-text-centered" v-if="emailStatus == 'success'"><b-icon class="mr025" icon="check" ></b-icon>{{ getText('emailSent', true) }}</p>
            <p class="has-text-centered" v-if="emailStatus == 'fail'"><b-icon class="mr025" icon="alert" ></b-icon>{{ getText('emailFail', true) }}</p>
            <p :class="['has-text-centered', 'is-size-6', setOpacity()]">{{ getText('completeEmail', true) }}</p>
            <div class="btnBar btnBarRight">
              <div class="mt1">
                <b-button v-if="confirmed==false" class="bg" :disabled="disableConfirmation" @click.stop="confirm">{{ getText('confirm', true) }}
                </b-button>
              </div>
              <b-button v-if="confirmed==true" :disabled="disableResend" class="bg mr2" @click.stop="resendEmail()">
                <span v-if="emailSent==true">{{ getText('resend', true) }}</span>
                <span v-else>{{ getText('send', true) }}</span>
              </b-button>
              <b-button v-if="confirmed==true" class="bg" @click.stop="reopen">{{ getText('reprint', true) }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
// import axios from "@/axios";
import ReturnItemSummary from "@/components/ReturnItemSummary";

export default {
  name: 'CustomerPaid',

  components:{
    ReturnItemSummary
  },

  data() {
    return {
      
      settlementChoice: 'pay', // If we implement more than one option, then this will need defaulting to '' 
     
      imageLink: process.env.VUE_APP_IMAGE_BASE_URL,

      locked: false,

      sendEmail: false,
      customerEmail: '',
      deductFromRefund: false,
      pageText: [],
      generalText: [],

      confirmed: false,
      isLoading: false,
      paperworkUrl: '',
      emailSent: false,
      emailStatus: ''

    }
  },
  props: [
    'data',
    'returnReasons'
  ],

  computed: {
    disableConfirmation()
    {
      let state = this.settlementChoice == '' || this.locked == true;

      if(this.sendEmail == true) // The checkbox is ticked so we need to lock up the confirmation, until a valid email has been added
      {
        state = true;

        if(this.$tools.validEmail(this.customerEmail))
        {
          state = false;
        }
      }

      return state;
    },

    disableResend()
    {
      let state = true;

      if(this.sendEmail == true && this.$tools.validEmail(this.customerEmail))
      {
        state = false;
      }

      return state;
    }
  },

  mounted()
  {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
  },

  methods:
  {
    getText(ref, general = false)
    {
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },
    
    addRefundReasonText()
    {
      for(let item of this.data.items) 
      {
        item['reasonText'] =  this.returnReasons.find(r => r.id == item.reason).value;
      }
    },

    confirm()
    {
      this.isLoading = true;

      this.addRefundReasonText();

      let data = {
        orderId: this.data.id,
        locale: this.$tools.getLocaleName()
      }

      if(this.sendEmail == true){
        data['sendEmail'] = true;
        data['customerEmail'] = this.customerEmail;
        this.emailStatus = 'sending';
      }

      // stringify and encode the data
      let jsonString = JSON.stringify(data);
      let postData = {
        data: btoa(jsonString)
      }

      this.$api.post('basic_return_paperwork.php', postData)
      .then(response => {
        if(response.status == 200 && response.data.status == 'success')
        {
          if(this.paperworkUrl == '')
          {
            const blob = this.dataURItoBlob(response.data.file);
            this.paperworkUrl = URL.createObjectURL(blob);
            window.open(this.paperworkUrl);
          }
          if(this.sendEmail == true)
          {
            if (response.data.emailSuccess == false)
            {
              this.openErrorMessage('violet609');
              this.emailStatus = 'fail';
            } else {
              this.emailSent = true;
              this.emailStatus = 'success';
            }
          }

          this.confirmed = true;
        } else {
          this.openErrorMessage('khaki588-1');
          this.emailStatus = '';
        }
        if(response.data.message == 'Unable to get return slip'){
          this.openErrorMessage('cyan376');
          this.emailStatus = '';
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.openErrorMessage('khaki588-2');
        this.emailStatus = '';
        this.isLoading = false;
      });
    },

    resendEmail()
    {
      this.isLoading = true;
      this.emailStatus = 'sending';

      let dataToPost = {
        orderId: this.data.id,
        locale: this.$tools.getLocaleName(),
        customerEmail: this.customerEmail
      }

      // stringify and encode the data
      let jsonString = JSON.stringify(dataToPost);
      let postData = {
        data: btoa(jsonString)
      }

      this.$api.post('send_email.php', postData)
      .then(response => {
        if( response.data.orders.status == 'success')
        {
          this.emailStatus = 'success';
        }
        else {
          this.emailStatus = 'fail';
          if (response.data.orders.message == 'too many email attempts') {
            this.openErrorMessage('silver630-c-p')
          }
        }
       
        this.isLoading = false;
      })
      .catch(() => {
        this.openErrorMessage('navy225');
        this.isLoading = false;
        this.emailStatus = 'fail';
      });
    },

    reopen()
    {
      window.open(this.paperworkUrl);
    },

    dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'application/pdf'});
      return blob;
    },
    
    setOpacity()
    {
      return this.sendEmail==1 && this.$tools.validEmail(this.customerEmail) == null ? '' : 'hideThis';
    }

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .b-radio + p { margin-left: 1.75em; }
  .hideThis { opacity: 0; }
</style>
